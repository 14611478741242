<template>
    <section class="w-full max-w-5xl px-6 py-6 mx-auto  text-white rounded-md shadow-md dark:bg-gray-800">
        <h2 class="text-3xl font-semibold text-center ">Get in touch</h2>        
        <div class="grid grid-cols-1 gap-6 mt-6 md:grid-cols-3">
            <a href="#" class="flex flex-col items-center px-4 py-3 rounded-md dark:text-gray-200 hover:bg-gray-700 ">
                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                </svg>

                <span class="mt-2">Downtown Dubai, UAE</span>
            </a>

            <a href="tel:+971 58 971 1418" class="flex flex-col items-center px-4 py-3 rounded-md dark:text-gray-200 hover:bg-gray-700 ">
                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>

                <span class="mt-2">+971 58 971 1418</span>
            </a>

            <a href="mailto:info@iplusinteriors.com" class="flex flex-col items-center px-4 py-3 rounded-md dark:text-gray-200 hover:bg-gray-700 ">
                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>

                <span class="mt-2">info@iplusinteriors.com</span>
            </a>
        </div>
        
        <form ref="form" @submit.prevent>
        <div class="mt-6 ">
            <div class="items-center -mx-2 md:flex">
                <div class="w-full mx-2">
                    <label class="block mb-2 text-sm font-medium  dark:text-gray-200">Name</label>

                    <input v-model="name" class="block w-full px-4 py-2 bg-black rounded border focus:ring-2 focus:ring-gray-900 text-base outline-none text-gray-100 leading-8 transition-colors duration-200 ease-in-out" type="text"
                    :class="[v$.name.$error ? 'border-red-600 focus:border-red-500' : 'border-gray-600 focus:border-gray-400']">
                </div>

                <div class="w-full mx-2 mt-4 md:mt-0">
                    <label class="block mb-2 text-sm font-medium  dark:text-gray-200">E-mail</label>

                    <input v-model="email" class="block w-full px-4 py-2 bg-black rounded border focus:ring-2 focus:ring-gray-900 text-base outline-none text-gray-100 leading-8 transition-colors duration-200 ease-in-out" type="email"
                    :class="[v$.email.$error ? 'border-red-600 focus:border-red-500' : 'border-gray-600 focus:border-gray-400']">
                </div>
            </div>

            <div class="w-full mt-4">
                <label class="block mb-2 text-sm font-medium dark:text-gray-200">Subject</label>

                 <input v-model="subject" class="block w-full px-4 py-2 bg-black rounded border focus:ring-2 focus:ring-gray-900 text-base outline-none text-gray-100 leading-8 transition-colors duration-200 ease-in-out" type="text"
                    :class="[v$.subject.$error ? 'border-red-600 focus:border-red-500' : 'border-gray-600 focus:border-gray-400']">
            </div>

            <div class="w-full mt-4">
                <label class="block mb-2 text-sm font-medium dark:text-gray-200">Message</label>

                <textarea v-model="message" class="block w-full h-40 px-4 py-2 bg-black rounded border focus:ring-2 focus:ring-gray-900 text-base outline-none text-gray-100 leading-8 transition-colors duration-200 ease-in-out" type="text"
                    :class="[v$.message.$error ? 'border-red-600 focus:border-red-500' : 'border-gray-600 focus:border-gray-400']"></textarea>
            </div>

            <div class="flex justify-center mt-6">
                <button @click="submitForm" class="px-4 py-2 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Send Message</button>
            </div>
        </div>
        </form>
    </section>
</template>

<script>
import { email, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core'
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
import config from '../email.js';

init(config.USER_ID);
export default ({
    name: "ContactUs",
    data: () => ({
        v$: useVuelidate(),
        name: '',
        email: '',
        subject: '',
        message: ''
    }),
    validations () {
        return {
            name: { required },
            email: { required, email },
            subject: { required },
            message: { required }
        }
    },
    methods: {
        submitForm() {
            this.v$.$validate()
            if (!this.v$.$error) {
                try {
                    emailjs.send(config.SERVICE_ID, config.TEMPLATE_ID,  {
                    from_name: this.name,
                    reply_to: this.email,
                    message: this.message,
                    subject: this.subject
                    }, config.USER_ID)

                } catch(error) {
                        console.log({error})
                }
                alert('Email sent!');
                this.$router.push('/');
            }
        },
    },
});

</script>
